// ** Zustand Imports
import { create } from 'zustand'

// ** Type Imports **
import TxnEnableFeatureStoreProps from '../types/TxnEnableFeatureStoreProps'

// =================================================================
const useTxnEnableFeatureStore = create<TxnEnableFeatureStoreProps>((set) => ({
  priorPeriodVendor: true,
  priorPeriodInternal: true,
  priorPeriodTransaction: true,
  validateQjeSources: true,
  openConfirmModal: false,
  consolidatedTxnView: false,
  propAccrualAmountHierarchy: 'Vendor.Internal.Calculated',
  negativeAccrualAmountToPrepaidEntry: false,
  considerBothDebitAndCreditValues: false,
  accountTypesToShowInReviewCenter: [],
  availableDimensions: [],

  setPriorPeriodVendor: (value: boolean) => set({ priorPeriodVendor: value }),
  setPriorPeriodInternal: (value: boolean) =>
    set({ priorPeriodInternal: value }),
  setPriorPeriodTransaction: (value: boolean) =>
    set({ priorPeriodTransaction: value }),
  setValidateQjeSources: (validateQjeSources: boolean) =>
    set({ validateQjeSources }),
  setConsolidatedTxnView: (consolidatedTxnView: boolean) =>
    set({ consolidatedTxnView }),
  setOpenConfirmModal: (value: boolean) => set({ openConfirmModal: value }),
  setPropAccrualAmountHierarchy: (value: string) =>
    set({ propAccrualAmountHierarchy: value }),
  setNegativeAccrualAmountToPrepaidEntry: (value: boolean) =>
    set({ negativeAccrualAmountToPrepaidEntry: value }),
  setConsiderBothDebitAndCreditValues: (value: boolean) =>
    set({ considerBothDebitAndCreditValues: value }),
  setAccountTypesToShowInReviewCenter: (accounts: string[]) => {
    set({ accountTypesToShowInReviewCenter: accounts })
  },
  setAvailableDimensions: (availableDimensions: string[]) => {
    set({ availableDimensions })
  }
}))

export default useTxnEnableFeatureStore
