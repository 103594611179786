//  ** React imports **
import React, { useEffect, useRef, useState } from 'react'

// ** AntD Imports **
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'

// ** Zustand / React Query Hooks **
import { UseQueryResult } from '@tanstack/react-query'
import useQje from '../hooks/useQje'
import useQjeStore from '../manager/qje-store'
import useQjeFiltering from '../hooks/useQjeFiltering'

// ** Custom Components **
import GDropdownBtn from '../../../components/gappify/GDropdownBtn'
import { getFilterMenu } from '../utils/qje-utils'
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'
import GButton from '../../../components/gappify/GButton'
import GIcon from '../../../components/gappify/GIcon'

// ** Third party imports **
import { faTrash, faEye, faPencil } from '@fortawesome/free-solid-svg-icons'
import {
  getDateFormat,
  getDateTimeMomentFormat,
  getMomentTimeFormat
} from '../../../utils/Date'

// ================================================
const FilterComponents: React.FC = () => {
  // ** States & Refs **
  const previewFilter = useRef<Record<string, any>>()
  const filterRecord = useRef<Record<string, any>>({})
  const clickedField = useRef<string>()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [filters, setFilters] = useState<Record<string, any>>([])
  const [formFieldsCount, setFormFieldsCount] = useState<number>(0) // To determine how many form fields to be displayed
  const [mode, setMode] = useState<'create' | 'edit' | undefined>()
  const [dynamicOptions, setDynamicOptions] = useState<Record<string, any>[]>(
    []
  )

  const [modalForm] = Form.useForm()
  let timeout: any

  // ** React Query hooks **
  const {
    getQjeSettings,
    fetchSuggestedValues,
    getUnfilteredReviewJe,
    getReviewJe
  } = useQje()
  const {
    getJeFilters,
    getCustomFilterDetails,
    isLoadingFilterCreation,
    isSuccessFilterCreation,
    isLoadingFilterUpdate,
    isSuccessFilterUpdate,
    handleCreate,
    handleEdit,
    isLoadingFilterDeletion,
    isSuccessFilterDeletion,
    handleDelete
  } = useQjeFiltering()
  const {
    data: jeFilters,
    isLoading: isLoadingJeFilters,
    isRefetching: isRefetchingJeFilters
  }: UseQueryResult<any, any> = getJeFilters
  const {
    data: qjeSettings,
    isLoading: isLoadingQjeSettings,
    isRefetching: isRefetchingQjeSettings
  }: UseQueryResult<any, any> = getQjeSettings
  const {
    data: suggestedValues,
    isLoading: isLoadingSuggestedValues,
    isRefetching: isRefetchingSuggestedValues,
    refetch: refetchSuggestedValues
  }: UseQueryResult<any, any> = fetchSuggestedValues
  const {
    data: customFilterDetails,
    isLoading: isLoadingCustomFilterDetails,
    isRefetching: isRefetchingCustomFilterDetails
  }: UseQueryResult<any, any> = getCustomFilterDetails
  const {
    data: unfilteredJe,
    isLoading: isLoadingUnfilteredJe,
    isRefetching: isRefetchingUnfilteredJe
  }: UseQueryResult<any, any> = getUnfilteredReviewJe
  const {
    // data: reviewJe,
    // isLoading: isLoadingReviewJe,
    // isRefetching: isRefetchingReviewJe,
    refetch: refetchReviewJe
  }: UseQueryResult<any, any> = getReviewJe

  // ** Zustand Hooks **
  const {
    filter,
    setFilter,
    searchTerm,
    setSearchTerm,
    setFocusedField,
    setReviewJePreviewData,
    previewMode,
    setPreviewMode,
    setSelectedLineGroups,
    setReviewedEditLines,
    setReviewedEditKey,
    focusedField,
    setPage,
    filter_fields,
    setFilterFields
  } = useQjeStore()

  // ** Custom Hooks **
  const RP = useRolesAndPermissions()

  // ** Trigger upon clicking Create New Filter / Edit / Preview buttons **
  const showModal = () => {
    setIsModalOpen(true)
  }

  // ** On click of Save button **
  const handleOk = () => {
    modalForm.submit()
  }

  // ** On click of Preview button **
  const handlePreview = () => {
    onFinish({ preview: true })
    setPreviewMode(true)
    setIsModalOpen(false)
  }

  // ** On click of Cancel / X buttons OR clicking outside of modal **
  const handleCancel = () => {
    // Reset states...
    setIsModalOpen(false)
    setFormFieldsCount(0)
    setDynamicOptions([])
    setPreviewMode(false)
    setFilterFields('')

    // Reset refs...
    clickedField.current = undefined
    filterRecord.current = {}

    // Reset form...
    modalForm.resetFields()
  }

  const handleOnClickDelete = () => {
    handleDelete({ endpoint: `/api/qje/filters/${filter}` })
  }

  // ** On click of a custom filter in the filter drop down **
  const handleOnClickFilter = ({ key }: any) => {
    // Click on Create New Filter
    if (key === 'new') {
      setMode('create')
      showModal()
    }
    // Click on a default filter
    else if (['all', 'unposted', 'posted', 'rejected'].includes(key)) {
      setMode(undefined)
      setFilter(key)

      setSelectedLineGroups([])
      setReviewedEditLines([])
      setReviewedEditKey(null)
    }
    // Click on a custom filter
    else if (key !== 'new') {
      setMode('edit')
      setFilter(key)

      setSelectedLineGroups([])
      setReviewedEditLines([])
      setReviewedEditKey(null)
    }

    // reset values...
    setPage(1)
    setDynamicOptions([])
    filterRecord.current = {}
    clickedField.current = undefined

    // Reset states of Preview filter
    setFilterFields('')
    setPreviewMode(false)
  }

  // ** For displaying label of selected filter **
  const getLabel = ({ array, key }: any) => {
    let matchingObject = array.find((obj: any) => obj.key === key.toString())
    if (matchingObject) return matchingObject.label
    else return ' '
  }

  // ** On click of Add Filter button **
  const addFormField = () => {
    setFormFieldsCount(formFieldsCount + 1)
  }

  // ** On click of `trash` button **
  const removeFormField = (index: number) => {
    const fieldValues: any = {}
    // Iterate from the index to the last field
    for (let i = index; i <= formFieldsCount; i++) {
      const currentFieldName = `field_${i}`
      const currentValueName = `value_${i}`
      // If it's not the last field, inherit the values from the next field
      if (i < formFieldsCount) {
        const nextField = modalForm.getFieldValue(`field_${i + 1}`)
        const nextValue = modalForm.getFieldValue(`value_${i + 1}`)
        fieldValues[currentFieldName] = nextField
        fieldValues[currentValueName] = nextValue
      } else {
        // For the last field, clear the value
        fieldValues[currentFieldName] = undefined
        fieldValues[currentValueName] = undefined
      }
    }

    // Update modal form...
    modalForm.setFieldsValue(fieldValues)

    // Update states...
    setFormFieldsCount(formFieldsCount - 1)
    setDynamicOptions([])

    // Update refs...
    clickedField.current = undefined
    delete filterRecord.current[modalForm.getFieldValue(`field_${index}`)]
  }

  // ** On click of Remove All button **
  const removeAllFormFields = () => {
    const fieldNames: string[] = []
    for (let i = 0; i <= formFieldsCount; i++) {
      fieldNames.push(`field_${i}`)
      fieldNames.push(`value_${i}`)
    }
    modalForm.resetFields(fieldNames)
    setFormFieldsCount(0)
  }

  // ** Convert qjeSettings?.responseData into a format compatible for AntD Select **
  const convertQjeSettingsToOptions = () => {
    const resultArray = []
    for (const key in qjeSettings?.responseData) {
      const { label, hidden } = qjeSettings?.responseData[key]
      if (!hidden) {
        resultArray.push({ label, value: key })
      }
    }
    return resultArray
  }

  // ** Functions - for form submission **
  const onFinish = (props?: { values?: Store; preview?: boolean }) => {
    // Convert `values` into a payload format required by BE
    let values = props?.values || modalForm.getFieldsValue()
    const processedFormValues: Record<string, any>[] = []
    const numFields = Object.keys(values).filter((key) =>
      key.startsWith('field_')
    ).length
    for (let i = 0; i < numFields; i++) {
      const fieldKey = `field_${i}`
      const valueKey = `value_${i}`
      const column = values[fieldKey]
      const value = values[valueKey]
      processedFormValues.push({ column, value })
    }

    // Payload to be passed to BE for create/edit filter
    const resultingPayload = {
      filter_name: modalForm.getFieldValue('name'),
      filter_fields: processedFormValues
    }

    if (props?.preview) {
      previewFilter.current = resultingPayload
      setFilterFields(JSON.stringify(processedFormValues))
    } else if (mode === 'create')
      handleCreate({ payload: resultingPayload, endpoint: '/api/qje/filters' })
    else if (mode === 'edit')
      handleEdit({
        payload: resultingPayload,
        endpoint: `/api/qje/filters/${filter}`
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  // Handler function to trigger the debounced API call
  const handleSearch = ({ value, index }: { value: string; index: number }) => {
    setFocusedField(modalForm.getFieldValue(`field_${index}`))
    setSearchTerm(value)
    clickedField.current = modalForm.getFieldValue(`field_${index}`)
  }

  const handleDefaultSearch = ({ index }: { index: number }) => {
    setDynamicOptions([])
    setFocusedField(modalForm.getFieldValue(`field_${index}`))
    setSearchTerm('')
    clickedField.current = modalForm.getFieldValue(`field_${index}`)

    if (focusedField === modalForm.getFieldValue(`field_${index}`))
      refetchSuggestedValues()
  }

  // Convert the getSuggestedValues?.responseData into a format compatible for AntD Select
  const convertSuggestedValuesToOptions = ({ data }: Record<string, any>) =>
    data?.map((item: { id: number; value: string }) => ({
      label: item.value,
      // value: item.id || item.value
      value: item.value
    }))

  const debounce = (func: (props: any) => any, delay: number) => {
    clearTimeout(timeout)
    timeout = setTimeout(func, delay)
  }

  const handleSearchDebounced = ({
    value,
    index
  }: {
    value: string
    index: number
  }) => debounce(() => handleSearch({ value, index }), 1000) // Adjust the delay time as needed

  // ** useEffect - populating menu item dropdown **
  useEffect(() => {
    if (!isLoadingJeFilters && !isRefetchingJeFilters) {
      setFilters(
        getFilterMenu({
          filtersObject: jeFilters?.responseData || {},
          enableCreateQje: RP.USER_HAS_CREATE_QJE_FILTER_PERMISSION
        })
      )
    }
  }, [
    isLoadingJeFilters,
    isRefetchingJeFilters,
    jeFilters,
    RP.USER_HAS_CREATE_QJE_FILTER_PERMISSION
  ])

  // ** This is only used for previewing **
  const generateFilteredLines = ({
    dataArray,
    filterRecord
  }: {
    dataArray: Record<string, any>[]
    filterRecord: Record<string, any>
  }) =>
    dataArray.filter((entry) =>
      Object.keys(filterRecord).every((key) => {
        let value = entry[key]
        //for boolean values
        if (value === 0) {
          value = 'No'
        } else if (value === 1) {
          value = 'Yes'
        }
        return filterRecord[key].includes(value)
      })
    )

  // ** This is only used for previewing **
  const arrangeByLineGroup = ({ data }: { data: Record<string, any> }) => {
    const rearrangedData: Record<string, any> = {}
    data.forEach((entry: Record<string, any>) => {
      const { line_group } = entry
      if (!rearrangedData[line_group]) {
        rearrangedData[line_group] = []
      }

      rearrangedData[line_group].push(entry)
    })
    return Object.values(rearrangedData).flat()
  }

  // ** useEffect - for fetching suggested options from BE
  // After 1 sec, if user is not typing anything, fetch suggested values from BE
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetchSuggestedValues()
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm, refetchSuggestedValues, focusedField])

  useEffect(() => {
    if (
      !isLoadingCustomFilterDetails &&
      !isRefetchingCustomFilterDetails &&
      customFilterDetails &&
      isModalOpen &&
      mode === 'edit' &&
      // Run this only if it's from Edit modal & not in preview mode
      // This will make the form retain its state when clicking Preview from Edit modal
      !previewMode
    ) {
      const details = customFilterDetails?.responseData
      const filterFields = JSON.parse(details?.filter_fields)
      // set number of fields available
      setFormFieldsCount(filterFields?.length)
      // formFields.name = details.name
      modalForm.setFieldValue('name', details?.name)
      // set form fields
      filterFields?.forEach((entry: Record<string, any>, index: number) => {
        modalForm.setFieldValue(`field_${index}`, entry.column)
        modalForm.setFieldValue(`value_${index}`, entry.value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isModalOpen,
    isLoadingCustomFilterDetails,
    isRefetchingCustomFilterDetails,
    customFilterDetails,
    mode
  ])

  // Handle success POST method...
  useEffect(() => {
    if (isSuccessFilterCreation) {
      // Handle success, e.g., show a success message, update UI, etc.
      setIsModalOpen(false)
      message.success('Filter saved.')
    }
  }, [isSuccessFilterCreation])

  // Handle success PUT method...
  useEffect(() => {
    if (isSuccessFilterUpdate) {
      // Handle success, e.g., show a success message, update UI, etc.
      refetchReviewJe()
      setIsModalOpen(false)
      message.success('Filter updated.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessFilterUpdate])

  // Detect preview filter action
  useEffect(() => {
    if (filter_fields) {
      refetchReviewJe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_fields])

  // Handle success DELETE method...
  useEffect(() => {
    if (isSuccessFilterDeletion) {
      setFilter('all')
      setIsModalOpen(false)
      message.success('Filter successfully deleted.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessFilterDeletion])

  // Update state when new suggested options are fetched...
  useEffect(() => {
    if (!isLoadingSuggestedValues && !isRefetchingSuggestedValues) {
      setDynamicOptions(
        convertSuggestedValuesToOptions({
          data: suggestedValues?.responseData
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSuggestedValues, isRefetchingSuggestedValues])

  useEffect(() => {
    if (!isLoadingUnfilteredJe && !isRefetchingUnfilteredJe) {
      const previewList = arrangeByLineGroup({
        data: generateFilteredLines({
          dataArray: unfilteredJe.responseData,
          filterRecord: filterRecord.current
        })
      })
      previewList.forEach((item: any, index: any) => {
        item.fixed_checkbox = item?.g_status === 'Unposted' || null
        item.fixed_history = item?.has_history || null

        // Handle custom columns here...
        // Columns that render plain text only...
        item.custom_g_debit =
          index % 2 === 0
            ? parseFloat(item.g_debit || item.g_credit)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : null
        item.custom_g_credit =
          index % 2 !== 0
            ? parseFloat(item.g_credit || item.g_debit)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : null
        item.custom_created_at =
          getDateTimeMomentFormat(
            item.created_at,
            getDateFormat('m/d/Y'),
            getMomentTimeFormat('h:i a')
          ) || null

        // Columns that render JSX elements...
        item.custom_transaction_number = item.transaction_number || null
        item.custom_g_threshold_support_document =
          item.g_threshold_support_document || null
        item.custom_g_reviewed = item.g_reviewed
        item.custom_g_resubmitted = item.g_resubmitted === 1 || null
        item.custom_g_is_prepaid = item.g_is_prepaid === 1 || null

        let modifiedRecord = item
        // Enforce alternating rowSpan (2 then 0)
        // This will override the rowSpan being returned by BE
        modifiedRecord.rowSpan = index % 2 === 0 ? 2 : 0

        // Enforce alternating colors between line_groups
        modifiedRecord.backgroundColor =
          index % 4 === 0 || index % 4 === 1 ? '#ffffff' : '#f9f9f9'
      })
      setReviewJePreviewData(previewList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUnfilteredJe, isRefetchingUnfilteredJe])

  return (
    <>
      {/* Dropdown button / Edit button / Preview button */}
      <Space>
        <GDropdownBtn
          dataTestId='je-filter-dropdown'
          dataCy='je-filter-dropdown'
          menuItems={filters}
          btnText={getLabel({ array: filters, key: filter })}
          children={
            <DownOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          onClick={handleOnClickFilter}
          defaultSelectedKeys={[getLabel({ array: filters, key: filter })]}
          loading={isLoadingJeFilters || isRefetchingJeFilters}
          overlayStyle={{
            maxHeight: '300px',
            overflowY: 'scroll',
            boxShadow: '5px 8px 24px 5px rgba(0, 0, 0, 0.1)'
          }}
        />
        {previewMode ? (
          <GButton
            type='text'
            className='g-btn-icon'
            icon={<GIcon icon={faEye} />}
            onClick={showModal}
          />
        ) : (
          !['all', 'unposted', 'posted', 'rejected'].includes(
            filter as string
          ) && (
            <GButton
              loading={
                isLoadingCustomFilterDetails || isRefetchingCustomFilterDetails
              }
              type='text'
              className='g-btn-icon'
              icon={<GIcon icon={faPencil} />}
              disabled={!RP.USER_HAS_EDIT_TM_FILTER_PERMISSION}
              onClick={() => {
                setMode('edit')
                showModal()
              }}
            />
          )
        )}
      </Space>

      {/* Modal for create / edit filter */}
      <Modal
        width={900}
        data-testid='qje-create-new-filter-modal'
        data-cy='qje-create-new-filter-modal'
        title={mode === 'create' ? 'Create New Filter' : 'Edit Filter'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ shape: 'round' }}
        okButtonProps={{ shape: 'round' }}
        footer={[
          <Button
            data-testid='qje-save-filter-btn'
            data-cy='qje-save-filter-btn'
            shape='round'
            type='primary'
            onClick={handleOk}
            htmlType='submit'
            loading={isLoadingFilterCreation || isLoadingFilterUpdate}
          >
            {mode === 'create' ? 'Save View' : 'Update View'}
          </Button>,

          <Popconfirm
            data-testid='qje-popconfirm-delete-filter'
            data-cy='qje-popconfirm-delete-filter'
            title='Are you sure to delete this filter?'
            onConfirm={() => handleOnClickDelete()}
            okText='Yes'
            cancelText='No'
          >
            <Button
              data-testid='qje-delete-view-btn'
              data-cy='qje-delete-view-btn'
              shape='round'
              type='default'
              loading={isLoadingFilterDeletion}
              htmlType='button'
            >
              Delete View
            </Button>
          </Popconfirm>,
          <Button
            data-testid='qje-preview-filter-btn'
            data-cy='qje-preview-filter-btn'
            shape='round'
            type='default'
            onClick={handlePreview}
            htmlType='button'
          >
            Preview
          </Button>,
          <Button
            data-testid='qje-cancel-filter-btn'
            data-cy='qje-cancel-filter-btn'
            shape='round'
            type='default'
            onClick={handleCancel}
            htmlType='button'
          >
            Cancel
          </Button>
        ]}
      >
        <Form
          data-testid='qje-create-new-filter-form'
          data-cy='qje-create-new-filter-form'
          name='create-edit-qje-filter'
          form={modalForm}
          layout='vertical'
          onFinish={(values: Record<string, any>[]) => onFinish({ values })}
          onFinishFailed={onFinishFailed}
        >
          {/* Name of the filter */}
          <Form.Item
            data-testid='qje-filter-name-form-item'
            data-cy='qje-filter-name-form-item'
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Enter name of the filter.' }]}
          >
            <Input
              data-testid='qje-filter-name-input'
              data-cy='qje-filter-name-input'
              maxLength={30}
            />
          </Form.Item>

          {/* Filter fields */}
          {Array.from({ length: formFieldsCount }, (_, index) => (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                gap: 20
              }}
            >
              {/* Select column... */}
              <Form.Item
                label='Field'
                name={`field_${index}`}
                rules={[{ required: true, message: 'Select field.' }]}
                style={{ flex: 1 }}
              >
                <Select
                  placeholder='Please select'
                  options={qjeSettings && convertQjeSettingsToOptions()}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? ('' as any))
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? ('' as any))
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? ('' as any)).toLowerCase()
                      )
                  }
                  onClick={(e) => {
                    if ((e.target as any).title) {
                      let settings = qjeSettings?.responseData
                      let clickedLabel = (e.target as any).title
                      for (let key in settings) {
                        if (
                          // find the apiKey ("key") that corresponds to clickedLabel
                          // eg. clickedLabel = "Class", key = 'g_class'
                          settings.hasOwnProperty(key) &&
                          settings[key].label === clickedLabel
                        )
                          clickedField.current = key
                      }
                    }
                  }}
                  onChange={(_, option: Record<string, any>) => {
                    delete filterRecord.current[clickedField.current as string]
                    filterRecord.current[option.value] = []
                    setDynamicOptions([])
                  }}
                />
              </Form.Item>
              {/* Select value... */}
              <Form.Item
                label='Value'
                name={`value_${index}`}
                rules={[{ required: true, message: 'Value is required.' }]}
                style={{ flex: 1 }}
              >
                <Select
                  loading={
                    isLoadingSuggestedValues || isRefetchingSuggestedValues
                  }
                  options={
                    ['g_resubmitted', 'g_reviewed'].includes(
                      modalForm.getFieldValue(`field_${index}`)
                    )
                      ? [
                          { value: 1, label: 'Yes' },
                          { value: 0, label: 'No' }
                        ]
                      : dynamicOptions
                  }
                  mode='multiple'
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? ('' as any))
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? ('' as any))
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? ('' as any)).toLowerCase()
                      )
                  }
                  onSearch={(value) => {
                    handleSearchDebounced({ value, index })
                  }}
                  onClick={(e) => {
                    handleDefaultSearch({ index })
                  }}
                  onChange={(_, options) => {
                    let field = modalForm.getFieldValue(`field_${index}`)
                    filterRecord.current[field] = options.map(
                      (option: Record<'label', any>) => option.label
                    )
                  }}
                />
              </Form.Item>

              {/* Delete button... */}
              <GButton
                type='text'
                className='g-btn-icon'
                onClick={() => removeFormField(index)}
                icon={<GIcon icon={faTrash} />}
              />
            </div>
          ))}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <GButton
              dataTestId='qje-add-filter-field-btn'
              dataCy='qje-add-filter-field-btn'
              btnText='Add Filter'
              onClick={addFormField}
              loading={isLoadingQjeSettings || isRefetchingQjeSettings}
            />
            {formFieldsCount > 0 && (
              <GButton
                dataTestId='qje-remove-all-filter-fields-btn'
                dataCy='qje-remove-all-filter-fields-btn'
                btnText='Remove All'
                onClick={removeAllFormFields}
              />
            )}
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default FilterComponents
