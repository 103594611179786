// ** React Imports **
import { FC, useState, useEffect } from 'react'

// ** AntD Imports **
import { Table, Modal, message } from 'antd'
import {
  HistoryOutlined,
  DownOutlined,
  ExportOutlined
} from '@ant-design/icons'

// ** Custom Components **
import GButton from './gappify/GButton'
import GDropdownBtn from './gappify/GDropdownBtn'

// ** QJE-only utils **
import { destructureQjeHistoryData } from '../pages/Qje2/utils/qje-history-utils'

// NOTE: this type is unused
type exportButtonMenu = {
  format: string
  sendRequest: () => Promise<any>
}

interface GlobalHistoryModalProps {
  // ** Basic props **
  reloadData?: () => Promise<any>
  columns?: any
  settings?: any
  isWithLabel?: boolean
  label?: JSX.Element | string

  // ** Props for overriding default behavior **
  modalTitle?: string
  onClickHistory?: () => void
  onCancel?: () => void
  openModal?: boolean
  overrideMainButton?: JSX.Element

  // ** Enabling/Disabling
  disableHistoryModal?: boolean

  // ** New props for QJE **
  isQje?: boolean

  // ** UNUSED: Export **
  hasExportButton?: boolean
  exportButtonOnClick?: () => any
  exportButtonMenu?: exportButtonMenu[]
  exportButtonLabel?: string
  disableExportButton?: boolean
}

const GlobalHistoryModal: FC<GlobalHistoryModalProps> = ({
  columns,
  disableExportButton,
  disableHistoryModal,
  exportButtonLabel,
  exportButtonMenu,
  exportButtonOnClick,
  hasExportButton,
  isQje,
  isWithLabel,
  label,
  modalTitle,
  onCancel,
  onClickHistory,
  openModal,
  overrideMainButton,
  reloadData,
  settings
}): JSX.Element => {
  // ** States **
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [historyData, setHistoryData] = useState<any>([])
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false)

  // ** Functions **
  const toggleShowHistoryModal = () => {
    onClickHistory && onClickHistory()
    setShowHistoryModal(!showHistoryModal)
  }

  // ** UNUSED: export **
  const exportButtonMenuOnClick = (callSendRequest: () => Promise<any>) => {
    callSendRequest()
  }

  useEffect(() => {
    if (reloadData) {
      const callReloadData = async () => {
        try {
          const { data } = await reloadData()
          if (isQje) {
            setHistoryData(data.log)
          } else {
            setHistoryData(data.data)
          }
        } catch (error) {
          message.error('An error occurred.')
          console.log('Error fetching history data', error)
        } finally {
          setIsLoadingData(false)
        }
      }

      if (showHistoryModal) {
        callReloadData()
        setIsLoadingData(true)
      }
    }

    // eslint-disable-next-line
  }, [showHistoryModal])

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (!disableHistoryModal) toggleShowHistoryModal()
        }}
      >
        {overrideMainButton ? (
          overrideMainButton
        ) : (
          <>
            <HistoryOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              className={(disableHistoryModal && 'disabled-item') || ''}
            />
            {isWithLabel && <span style={{ paddingLeft: '8px' }}>{label}</span>}
          </>
        )}
      </div>
      <Modal
        data-testid='global-history-modal'
        data-cy='global-history-modal'
        title={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingInlineEnd: '25px'
            }}
          >
            <div>{modalTitle || 'History'}</div>

            {/* NOTE: this is unused */}
            {hasExportButton && exportButtonMenu && (
              <GDropdownBtn
                menuItems={exportButtonMenu}
                btnText={exportButtonLabel || 'Export'}
                btnIcon={
                  <ExportOutlined
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                }
                children={
                  <DownOutlined
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                }
                onClick={exportButtonMenuOnClick}
                className='g-btn-default'
                disabled={disableExportButton}
              />
            )}
            {hasExportButton && !exportButtonMenu && (
              <GButton
                btnText={exportButtonLabel || 'Export'}
                icon={
                  <ExportOutlined
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                }
                onClick={exportButtonOnClick}
                className='g-btn-default'
              />
            )}
          </div>
        }
        open={showHistoryModal}
        onCancel={() => {
          onCancel && onCancel()
          toggleShowHistoryModal()
        }}
        width={2000}
        bodyStyle={{ height: 600 }}
        footer={null}
      >
        <Table
          data-testid='global-history-table'
          data-cy='global-history-table'
          loading={isLoadingData}
          dataSource={
            isQje
              ? !isLoadingData
                ? destructureQjeHistoryData(historyData)
                : []
              : !isLoadingData
              ? historyData
              : []
          }
          columns={(!isLoadingData && columns) || []}
          pagination={false}
          scroll={{ x: 'max-content', y: 500 }}
        />
      </Modal>
    </>
  )
}

GlobalHistoryModal.defaultProps = {
  label: 'History Log'
}

export default GlobalHistoryModal
