import { axiosTaskManagerInstance } from '../helpers/axios'
import TaskApiParams from '../types/TaskApiParams'

export const getTaskManagerDynamicColumn = () => {
  // TODO: remove params object when MS-TM URL endpoints are fixed
  const params = {
    apiKey: '17b923913a6b4b66cf8ce2dedb795b55',
    companyId: 142
  }

  return axiosTaskManagerInstance
    .get('api/task-manager/settings', { params }) // TODO: change api endpoint to '/api/task-manager-settings'
    .catch((error) => console.error(error))
}

export const getSourceCoaOptions = (type: string) => {
  // TODO: remove params object when MS-TM URL endpoints are fixed
  const params = {
    apiKey: '17b923913a6b4b66cf8ce2dedb795b55',
    companyId: 142,
    type
  }

  return axiosTaskManagerInstance
    .get('api/source/chart-of-accounts', { params }) // TODO: change api endpoint (find out the equivalent endpoint for MS-TM)
    .catch((error) => console.error(error))
}

export const copyVendorTasks = (id: number) => {
  let params = {
    id
  }

  return axiosTaskManagerInstance
    .post('api/vendor-tasks/copy-from-vendor-task', params)
    .catch((err) => {
      console.log(err)
    })
}

export const getVendorTasks = (params?: TaskApiParams) => {
  return axiosTaskManagerInstance
    .get('api/vendor-tasks', { params })
    .catch((err) => {
      console.log(err)
    })
}

export const deleteVendorTasks = (
  row_id: number
  // cid?: number,
  // token?: string,
) => {
  return axiosTaskManagerInstance
    .delete(`api/vendor-tasks/${row_id}`)
    .catch((err) => {
      console.log(err)
    })
}

export const exportTasks = (fileType: string, params: any) => {
  return axiosTaskManagerInstance.get(`api/vendor-tasks-download/${fileType}`, {
    params
  })
}

export const deleteFilter = (filterId: string) => {
  return axiosTaskManagerInstance.delete(`api/task-manager/filters/${filterId}`)
}

export const getAllFilters = () => {
  return axiosTaskManagerInstance.get(`api/task-manager/filters`)
}

export const getFilterDetails = (filterId: string) => {
  return axiosTaskManagerInstance.get(`api/task-manager/filters/${filterId}`)
}

export const getFilterFields = () => {
  return axiosTaskManagerInstance.get(
    `api/task-manager/filter/getSelectedFields`
  )
}

export const createFilter = (params: any) => {
  return axiosTaskManagerInstance.post(`api/task-manager/filters`, params)
}

export const previewFilter = (params: any) => {
  return axiosTaskManagerInstance.post(
    `api/task-manager/preview-filters`,
    params
  )
}

export const updateFilter = (filterId: string, params: any) => {
  return axiosTaskManagerInstance.put(
    `api/task-manager/filters/${filterId}`,
    params
  )
}

export const getOptions = (apiName: string, term: string) => {
  return axiosTaskManagerInstance.get(
    `api/task-manager/options/${apiName.trim()}`,
    {
      params: { term: term }
    }
  )
}

export const getAccrualRules = () => {
  return axiosTaskManagerInstance.get(`api/accrual-rules`)
}

export const toggleAccrualRuleStatus = (id: number) => {
  return axiosTaskManagerInstance.put(`api/accrual-rules/${id}/toggle`)
}

export const deleteAccrualRules = (id: number) => {
  return axiosTaskManagerInstance.delete(`api/accrual-rules/${id}`)
}

export const reorderAccrualRules = (payload: {
  row: { id: number; weight: number }[]
}) => {
  return axiosTaskManagerInstance.put('api/accrual-rules/order', payload)
}

export const createAccrualRules = (payload: any) => {
  return axiosTaskManagerInstance.post('api/accrual-rules', payload)
}

export const updateAccrualRules = (id: number, payload: any) => {
  return axiosTaskManagerInstance.put(`api/accrual-rules/${id}`, payload)
}

export const getAccrualRulesById = (id: number) => {
  return axiosTaskManagerInstance
    .get(`api/accrual-rules/${id}`)
    .catch((err) => {
      console.log(err)
    })
}

export const getSourceCoaList = () => {
  return axiosTaskManagerInstance
    .get('api/source/chart-of-accounts')
    .catch((err) => {
      console.log(err)
    })
}

export const getSourceCoaOptionsWithInactive = (type: string) => {
  const params = {
    type: type,
    includesInactive: 1
  }
  return axiosTaskManagerInstance
    .get('api/source/chart-of-accounts', { params })
    .catch((err) => {
      console.log(err)
    })
}

// eslint-disable-next-line
