import { FC, SetStateAction, useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Checkbox,
  Select,
  Row,
  Col,
  Popconfirm,
  message
} from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { AxiosError } from 'axios'
import getUserById from '../services/getUserById'

import Role from '../constants/Role'
import useGetSourceCoaOptions from '../hooks/useGetSourceCoaOptions'
import useGetRolesOptions from '../hooks/useGetRolesOptions'
import updateUser from '../services/updateUser'
import createUser from '../services/createUser'
import cleanPayload from '../../../utils/cleanPayload'

const { Option } = Select

interface UserModalProps {
  visible: boolean
  onCancelModal?: () => void
  setModalVisible: (value: SetStateAction<boolean>) => void
  editRecordId?: number | undefined
  loadDataSource: () => void
}

interface UserData {
  department?: string
  department_ids?: string[]
  email?: string
  first_name?: string
  glaccount_ids?: string[]
  last_name?: string
  phone?: string
  position?: string
  roles?: number[]
  status?: number
  subsidiary_ids?: string[]
  user_data_access?: string
  username?: string
}

const UserModal: FC<UserModalProps> = ({
  visible,
  onCancelModal,
  setModalVisible,
  editRecordId,
  loadDataSource
}: UserModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(true)
  const [showRoleConfirm, setShowRoleConfirm] = useState(false)
  const [showSubConfirm, setShowSubConfirm] = useState(false)
  const [showDeptConfirm, setShowDeptConfirm] = useState(false)
  const [showGLAcctConfirm, setShowGLAcctConfirm] = useState(false)
  const { roleOptions, getRoleOptions } = useGetRolesOptions()
  const {
    subsidiaryOptions,
    departmentOptions,
    glAccountOptions,
    getDataAccessOptions
  } = useGetSourceCoaOptions()
  const [isFieldDisabled, setIsFieldDisabled] = useState(false)
  const [userData, setUserData] = useState<UserData>({})

  const handleSaveUser = async (value: any) => {
    try {
      let response: any
      if (editRecordId) {
        response = await updateUser(editRecordId!, cleanPayload(value))
      } else {
        response = await createUser(value)
      }

      if (response) {
        if (editRecordId) {
          message.success('Successfully updated!')
        } else {
          message.success('Successfully created!')
        }
        setModalVisible(false)
        loadDataSource()
        form.resetFields()
      }
    } catch (error) {
      const { response } = error as AxiosError
      const errMsg: any = response?.data
      if (errMsg.errors) {
        if (errMsg.errors[Object.keys(errMsg.errors)[0]]) {
          message.error(errMsg.errors[Object.keys(errMsg.errors)[0]])
        } else {
          message.error(errMsg.message)
        }
      } else {
        if (editRecordId) {
          message.error('Unable to update user.')
        } else {
          message.error('Unable to create user.')
        }
      }
      console.log(response)
    }
  }

  const onChangeChecboxAccess = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue('subsidiary_ids', [])
      form.setFieldValue('department_ids', [])
      form.setFieldValue('glaccount_ids', [])
      setUserData({ ...userData, user_data_access: 'Full' })
    } else {
      setUserData({ ...userData, user_data_access: 'Restricted' })
    }
  }

  const onSelectRoles = (value: number) => {
    if (value === Role.SYSTEM_ADMINISTRATOR) {
      setShowRoleConfirm(true)
    }
  }

  const onDeselectRoles = (value: number) => {
    if (value === Role.SYSTEM_ADMINISTRATOR) {
      setIsFieldDisabled(false)
    }
  }

  const handleConfirmRole = () => {
    setUserData({ ...userData, user_data_access: 'Full' })
    form.setFieldValue('subsidiary_ids', [])
    form.setFieldValue('department_ids', [])
    form.setFieldValue('glaccount_ids', [])
    setIsFieldDisabled(true)
    setShowRoleConfirm(false)
  }

  const onDeselectDataAccess = () => {
    if (
      form.getFieldValue('subsidiary_ids') &&
      form.getFieldValue('subsidiary_ids').length === 0 &&
      form.getFieldValue('department_ids') &&
      form.getFieldValue('department_ids').length === 0 &&
      form.getFieldValue('glaccount_ids') &&
      form.getFieldValue('glaccount_ids').length === 0
    ) {
      setUserData({ ...userData, user_data_access: 'Full' })
    }
  }

  const OnClickDataAccess = (field: string, options: any[]) => {
    if (options.length === 0) {
      setIsLoading(true)
      getDataAccessOptions(field, setIsLoading)
    }
  }

  const onSelectDataAccess = (
    idString: string,
    idString2: string,
    setShowConfirm: (value: SetStateAction<boolean>) => void
  ) => {
    showPopConfirm(idString, idString2, setShowConfirm)
    if (!showGLAcctConfirm || !showSubConfirm || !showDeptConfirm)
      setUserData({ ...userData, user_data_access: 'Restricted' })
  }

  const showPopConfirm = (
    field1: string,
    field2: string,
    setShowConfirm: (value: SetStateAction<boolean>) => void
  ) => {
    if (
      form.getFieldValue(field1)?.length > 0 ||
      form.getFieldValue(field2)?.length > 0
    ) {
      setShowConfirm(true)
    }
  }

  const onRolePopConfirm = () => {
    setShowRoleConfirm(false)
    let newRoles = form
      .getFieldValue('roles')
      .filter((id: number) => id !== Role.SYSTEM_ADMINISTRATOR)
    form.setFieldValue('roles', newRoles)
  }

  const handleDataAccessConfirm = (
    idString: string,
    idString2: string,
    setShowConfirm: (value: SetStateAction<boolean>) => void
  ) => {
    form.setFieldValue(idString, [])
    form.setFieldValue(idString2, [])
    form.setFieldValue('full_access', false)
    setShowConfirm(false)
  }

  const forceStringConversion = (arr: string[] | number[]) => {
    return arr.map((item) => String(item))
  }

  const getDataAccessKey = (data: any) => {
    if (data.subsidiary_ids?.length > 0) {
      form.setFieldValue(
        'subsidiary_ids',
        forceStringConversion(data.subsidiary_ids)
      )
      return 'Subsidiary'
    } else if (data.department_ids?.length > 0) {
      form.setFieldValue(
        'department_ids',
        forceStringConversion(data.department_ids)
      )
      return 'Department'
    } else if (data.glaccount_ids?.length > 0) {
      form.setFieldValue(
        'glaccount_ids',
        forceStringConversion(data.glaccount_ids)
      )
      return 'GLAccount'
    } else {
      return null
    }
  }

  const onChangeEmail = (e: any) => {
    let value = e.target.value
    form.setFieldValue('username', value)
  }

  const getRoles = () => {
    const roles: {
      label: string
      disabled?: boolean
      value: number | string
      hidden?: boolean
    }[] = []
    if (isLoading) {
      roles.push({
        label: 'Loading options...',
        disabled: true,
        value: ''
      })
      return roles
    }

    const hiddenRoles = [Role.GAPPIFY_ADMINISTRATOR, Role.PUBLIC_API_USER]

    roleOptions &&
      roleOptions.forEach((item: any) => {
        if (hiddenRoles.find((element) => item.id === element)) {
          roles.push({
            label: item.display_name,
            value: item.id || '',
            hidden: true
          })
        } else {
          roles.push({
            label: item.display_name,
            value: item.id || ''
          })
        }
      })
    return roles
  }

  useEffect(() => {
    if (editRecordId && visible) {
      getUserById(editRecordId).then((response: any) => {
        if (response && response.data) {
          setUserData(response.data)
          form.setFieldsValue(response.data)
          if (roleOptions.length === 0) {
            getRoleOptions(setIsLoading)
          }
          if (response.data.roles.includes(Role.SYSTEM_ADMINISTRATOR)) {
            setIsFieldDisabled(true)
          }
          let key = getDataAccessKey(response.data)
          if (key) getDataAccessOptions(key, setIsLoading)
        }
      })
    }
    // eslint-disable-next-line
  }, [editRecordId])

  return (
    <Modal
      data-testid='user-create-modal'
      data-cy='user-create-modal'
      open={visible}
      onOk={form.submit}
      title={`${editRecordId ? 'Edit' : 'Add'} User`}
      width={850}
      okText={editRecordId ? 'Update' : 'Save'}
      onCancel={() => {
        form.resetFields()
        setIsFieldDisabled(false)
        onCancelModal && onCancelModal()
      }}
      className='user-modal'
    >
      <Form
        data-testid='user-create-form'
        data-cy='user-create-form'
        layout='horizontal'
        form={form}
        onFinish={handleSaveUser}
      >
        <div className='user-label'>Account Details</div>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              data-testid='user-first-name-input'
              data-cy='user-first-name-input'
              label='First Name'
              name='first_name'
              rules={[
                {
                  required: true,
                  message: `This is a required field.`
                },
                {
                  max: 255,
                  message: 'This field should be less than 256 character'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              data-testid='user-email-input'
              data-cy='user-email-input'
              label='Email Address'
              name='email'
              rules={[
                {
                  required: true,
                  message: `This is a required field.`
                },
                {
                  type: 'email',
                  message: 'Input a valid email.'
                }
              ]}
            >
              <Input
                onChange={onChangeEmail}
                disabled={editRecordId ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              data-testid='user-last-name-input'
              data-cy='user-last-name-input'
              label='Last Name'
              name='last_name'
              rules={[
                {
                  required: true,
                  message: `This is a required field.`
                },
                {
                  max: 255,
                  message: 'This field should be less than 256 character'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              data-testid='user-username-input'
              data-cy='user-username-input'
              label='Username'
              name='username'
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              data-testid='user-position-input'
              data-cy='user-position-input'
              label='Position'
              name='position'
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              data-testid='user-department-input'
              data-cy='user-department-input'
              label='Department'
              name='department'
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              data-testid='user-phone-input'
              data-cy='user-phone-input'
              label='Phone'
              name='phone'
              rules={[
                {
                  max: 45,
                  message: 'This field should be less than 46 character'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <div className='user-label'>Roles</div>
        <Row gutter={16}>
          <Col span={24}>
            <Popconfirm
              title='Are you sure you want to add System-Administrator?'
              okText='Yes'
              open={showRoleConfirm}
              className='user-confirm'
              onConfirm={handleConfirmRole}
              onCancel={onRolePopConfirm}
            >
              <Form.Item
                data-testid='user-roles-select'
                data-cy='user-roles-select'
                name='roles'
                label='Roles'
                className='user-select-label roles-label'
                rules={[
                  {
                    required: true,
                    message: `This is a required field.`
                  }
                ]}
              >
                <Select
                  placeholder='Please select'
                  mode='multiple'
                  optionFilterProp='children'
                  onClick={() => getRoleOptions(setIsLoading)}
                  onSelect={onSelectRoles}
                  onDeselect={onDeselectRoles}
                  options={getRoles()}
                  filterSort={(optionA, optionB) => {
                    const filterA = optionA.label.toLowerCase()
                    const filterB = optionB.label.toLowerCase()
                    if (filterA < filterB) {
                      return -1
                    }
                    if (filterA > filterB) {
                      return 1
                    }
                    return 0
                  }}
                />
              </Form.Item>
            </Popconfirm>
          </Col>
        </Row>
        <div className='user-label'>Data Access</div>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              data-testid='user-full-access-checkbox'
              data-cy='user-name-checkbox'
              label='Full Access'
              valuePropName='checked'
              initialValue={userData?.user_data_access !== 'Restricted'}
            >
              <Checkbox
                checked={userData?.user_data_access !== 'Restricted'}
                onChange={onChangeChecboxAccess}
                disabled={isFieldDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Popconfirm
              title='Selecting a different field will remove all current field values. Are you sure you want to proceed?'
              okText='Yes'
              open={showSubConfirm}
              className='user-confirm'
              onConfirm={() =>
                handleDataAccessConfirm(
                  'department_ids',
                  'glaccount_ids',
                  setShowSubConfirm
                )
              }
              onCancel={() => {
                setShowSubConfirm(false)
                form.setFieldValue('subsidiary_ids', [])
              }}
            >
              <Form.Item
                data-testid='user-subsidiary-select'
                data-cy='user-subsidiary-select'
                name='subsidiary_ids'
                label='Subsidiary'
                className='user-select-label'
              >
                <Select
                  placeholder='Please select'
                  mode='multiple'
                  optionFilterProp='children'
                  disabled={showSubConfirm || isFieldDisabled}
                  onClick={() =>
                    OnClickDataAccess('Subsidiary', subsidiaryOptions)
                  }
                  onSelect={() =>
                    onSelectDataAccess(
                      'department_ids',
                      'glaccount_ids',
                      setShowSubConfirm
                    )
                  }
                  onDeselect={onDeselectDataAccess}
                >
                  {isLoading ? (
                    <Option disabled value=''>
                      Loading options...
                    </Option>
                  ) : (
                    subsidiaryOptions &&
                    subsidiaryOptions.map((item: any) => {
                      return (
                        <Option
                          key={item.g_source_system_id}
                          value={item.g_source_system_id}
                        >
                          {item.value}
                        </Option>
                      )
                    })
                  )}
                </Select>
              </Form.Item>
            </Popconfirm>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Popconfirm
              title='Selecting a different field will remove all current field values. Are you sure you want to proceed?'
              okText='Yes'
              open={showDeptConfirm}
              className='user-confirm'
              onConfirm={() =>
                handleDataAccessConfirm(
                  'subsidiary_ids',
                  'glaccount_ids',
                  setShowDeptConfirm
                )
              }
              onCancel={() => {
                setShowDeptConfirm(false)
                form.setFieldValue('department_ids', [])
              }}
            >
              <Form.Item
                data-testid='user-department-select'
                data-cy='user-department-select'
                name='department_ids'
                label='Department'
                className='user-select-label'
              >
                <Select
                  placeholder='Please select'
                  mode='multiple'
                  disabled={showDeptConfirm || isFieldDisabled}
                  optionFilterProp='children'
                  onClick={() =>
                    OnClickDataAccess('Department', departmentOptions)
                  }
                  onSelect={() =>
                    onSelectDataAccess(
                      'subsidiary_ids',
                      'glaccount_ids',
                      setShowDeptConfirm
                    )
                  }
                  onDeselect={onDeselectDataAccess}
                >
                  {isLoading ? (
                    <Option disabled value=''>
                      Loading options...
                    </Option>
                  ) : (
                    departmentOptions &&
                    departmentOptions.map((item: any) => {
                      return (
                        <Option
                          key={item.g_source_system_id}
                          value={item.g_source_system_id}
                        >
                          {item.value}
                        </Option>
                      )
                    })
                  )}
                </Select>
              </Form.Item>
            </Popconfirm>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Popconfirm
              title='Selecting a different field will remove all current field values. Are you sure you want to proceed?'
              okText='Yes'
              open={showGLAcctConfirm}
              className='user-confirm'
              onConfirm={() =>
                handleDataAccessConfirm(
                  'subsidiary_ids',
                  'department_ids',
                  setShowGLAcctConfirm
                )
              }
              onCancel={() => {
                setShowGLAcctConfirm(false)
                form.setFieldValue('glaccount_ids', [])
              }}
            >
              <Form.Item
                data-testid='user-glaccount-select'
                data-cy='user-glaccount-select'
                name='glaccount_ids'
                label='GL Account'
                className='user-select-label'
              >
                <Select
                  placeholder='Please select'
                  mode='multiple'
                  disabled={showGLAcctConfirm || isFieldDisabled}
                  optionFilterProp='children'
                  onClick={() =>
                    OnClickDataAccess('GLAccount', glAccountOptions)
                  }
                  onSelect={() =>
                    onSelectDataAccess(
                      'subsidiary_ids',
                      'department_ids',
                      setShowGLAcctConfirm
                    )
                  }
                  onDeselect={onDeselectDataAccess}
                >
                  {isLoading ? (
                    <Option disabled value=''>
                      Loading options...
                    </Option>
                  ) : (
                    glAccountOptions &&
                    glAccountOptions.map((item: any) => {
                      return (
                        <Option
                          key={item.g_source_system_id}
                          value={item.g_source_system_id}
                        >
                          {item.value}
                        </Option>
                      )
                    })
                  )}
                </Select>
              </Form.Item>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UserModal

UserModal.defaultProps = {
  visible: false
}
