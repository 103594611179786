// ** React Imports **
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'

// ** React Query imports
import { useQueryClient } from '@tanstack/react-query'

// ** Custom Component Imports
import GButton from '../../../../components/gappify/GButton'
import CheckboxRow from './components/CheckboxRow'

// ** Hook Imports
import useTxnEnableFeatures from './hooks/useTxnEnableFeatures'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import useFetchSettingsConsolidated from './hooks/useFetchSettingsConsolidated'

// ** Zustand Imports
import useTxnEnableFeatureStore from './manager/useTxnEnableFeatureStore'
import useTxnGlobalSettingsStore from '../../../../global/useTxnGlobalSettingsStore'

// ** Antd imports
import {
  Skeleton,
  Space,
  Popconfirm,
  Select,
  Checkbox,
  Tooltip,
  message,
  Typography
} from 'antd'
import MonthlyData from '../ScheduleManager/mock/MonthlyData'
import useScheduleManager from '../ScheduleManager/hooks/useScheduleManager'

// ** Utils imports
import isGappifyAdministrator from '../../utils/isGappifyAdministrator'
import useFetchReviewCenterOptions from './hooks/useFetchReviewCenterOptions'

// ** Styles imports
import styles from './index_q3.module.css'
import TooltipIcon from './components/TooltipIcon'

// ** Constants imports
import { TOOLTIP_MSG } from './data/constants'

const { Title } = Typography
const { Option } = Select

// ===================================================================
const TxnEnableFeaturesQ3 = () => {
  // ** Hooks **
  const { getTxnEnableFeatures, handleSave, putIsLoading } =
    useTxnEnableFeatures()
  const { data, isSuccess, isLoading, isRefetching } = getTxnEnableFeatures
  const { isLoading: isFeatureFlagsLoading } = useFeatureFlags()
  const { getScheduleManagerMonthly } = useScheduleManager()
  const { isLoading: isLoadingSchedule } = getScheduleManagerMonthly
  const { rowsFromBackend } = MonthlyData()
  const { data: fetchedOptions, isLoading: isLoadingOptions } =
    useFetchReviewCenterOptions()
  const {
    data: fetchedConsoSettings,
    isLoading: isLoadingFetchedConsoSettings
  } = useFetchSettingsConsolidated()

  // ** Zustand states **
  const { txnGlobalSettings }: Record<string, any> = useTxnGlobalSettingsStore()
  const {
    priorPeriodVendor,
    priorPeriodInternal,
    priorPeriodTransaction,
    validateQjeSources,
    consolidatedTxnView,
    propAccrualAmountHierarchy,
    negativeAccrualAmountToPrepaidEntry,
    setPriorPeriodVendor,
    setPriorPeriodInternal,
    setPriorPeriodTransaction,
    setValidateQjeSources,
    setConsolidatedTxnView,
    setPropAccrualAmountHierarchy,
    setNegativeAccrualAmountToPrepaidEntry,
    considerBothDebitAndCreditValues,
    setConsiderBothDebitAndCreditValues,
    accountTypesToShowInReviewCenter,
    setAccountTypesToShowInReviewCenter,
    availableDimensions,
    setAvailableDimensions
  } = useTxnEnableFeatureStore()

  const [showPopConfirm, setShowPopConfirm] = useState<boolean>(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const [isTooltipVisibleRC, setIsTooltipVisibleRC] = useState<boolean>(false)
  const [reportingCurrency, setReportingCurrency] = useState<string>('')
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const isConsoFlagDisabled = () => {
    const oldCondition = showPopConfirm || data?.consolidated_transaction_lines
    const newCondition =
      fetchedConsoSettings?.consolidated_transaction_lines_disabled // New condition from AC-1348
    return oldCondition || newCondition
  }

  const getConsoTooltip = () => {
    if (
      !data?.consolidated_transaction_lines && // Conso flag should not be enabled yet
      fetchedConsoSettings?.consolidated_transaction_lines_disabled // New settings flag to disable the conso flag & display tooltip
    )
      return TOOLTIP_MSG.CONSO.DISABLED
    return TOOLTIP_MSG.CONSO.DEFAULT
  }

  useEffect(() => {
    if (!isLoading && !isRefetching && isSuccess) {
      setPriorPeriodVendor(
        data?.prior_period_information_on_vendor_accrual_forms!
      )
      setPriorPeriodInternal(
        data?.prior_period_information_on_internal_accrual_forms!
      )
      setPriorPeriodTransaction(
        data?.prior_period_information_on_transaction_lines!
      )
      setValidateQjeSources(data?.validate_qje_sources!)
      setConsolidatedTxnView(data?.consolidated_transaction_lines!)
      setPropAccrualAmountHierarchy(data?.proposed_accrual_amount_hierarchy!)
      setNegativeAccrualAmountToPrepaidEntry(
        data?.negative_accrual_amount_to_prepaid_entry!
      )
      setConsiderBothDebitAndCreditValues(
        data?.consider_both_debit_and_credit_values!
      )
      setAccountTypesToShowInReviewCenter(
        data?.account_types_to_show_in_review_center!
      )
      setAvailableDimensions(data?.available_dimensions || [])
      setReportingCurrency(data?.reporting_currency || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isRefetching, isLoading])

  useEffect(() => {
    const invalidateSchedManagerQueries = async () => {
      if (!isLoadingSchedule) {
        await queryClient.invalidateQueries(['schedule-manager-monthly'])
      }
    }
    invalidateSchedManagerQueries()
    // eslint-disable-next-line
  }, [queryClient])

  useEffect(() => {
    if (isFeatureFlagsLoading) return
    if (!isGappifyAdministrator()) {
      // if feature flag is off OR user has no permission
      // redirect to error page
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || isLoadingFetchedConsoSettings)
    return (
      <>
        <Skeleton.Input active block={true} size='large' />
        <br />
        <br />
        <Space direction='vertical'>
          <Skeleton.Input active size='large' />
          <Skeleton.Input active size='large' />
          <Skeleton.Input active size='large' />
        </Space>
        <br />
        <br />
        <Skeleton.Input active block={true} size='large' />
        <br />
        <br />
        <Space direction='vertical'>
          <Skeleton.Input active size='large' />
          <Skeleton.Input active size='large' />
          <Skeleton.Button active size='large' />
        </Space>
      </>
    )

  const isDisabledProposedAccAmountHierarchy = () => {
    const checkLaunchDate = isBeforeLaunchDate()

    return !consolidatedTxnView || !checkLaunchDate
  }

  const isBeforeLaunchDate = () => {
    const currentDatePST = moment().tz('America/Los_Angeles')

    // Calculate the 16th of the last month in PST
    const startOfLastMonthPST = currentDatePST
      .clone()
      .subtract(1, 'months')
      .date(16)

    //Get erliest date in scheduler launch dates
    const earLiestDate = getEarliestLaunchDate()

    return currentDatePST.isBetween(
      startOfLastMonthPST,
      earLiestDate!,
      null,
      '[]'
    ) // '[]' includes both the start and end dates
  }

  const getEarliestLaunchDate = () => {
    const schedules = rowsFromBackend

    if (schedules.length > 0) {
      const dates = schedules.map((schedule) =>
        new Date(schedule.nextRunDate!).getTime()
      )
      // Finding the latest date
      return new Date(Math.min(...dates))
    }
  }

  const handleOnChangeField = (value: string) => {
    setPropAccrualAmountHierarchy(value)
  }

  const handleHoverChange = (visible: boolean) => {
    setIsTooltipVisible(visible)
  }

  const handleHoverChangeRC = (visible: boolean) => {
    if (!consolidatedTxnView) {
      setIsTooltipVisibleRC(visible)
    }
  }

  return (
    <div className={styles.container}>
      <Title level={4} className={styles.section}>
        General
      </Title>
      <div className={styles.content}>
        <div className={styles.group}>
          <Title level={5}>
            Reporting Currency
            <TooltipIcon message='This is a placeholder tooltip message for Reporting Currency.' />
          </Title>
          <Select
            className={styles.select}
            placeholder='Please select'
            defaultValue={data?.reporting_currency}
            options={[
              {
                label: 'USD',
                value: 'USD'
              },
              {
                label: 'EUR',
                value: 'EUR'
              },
              {
                label: 'GBP',
                value: 'GBP'
              }
            ]}
            data-cy='reporting-currency-select'
            data-testid='reporting-currency-select'
            onChange={(value: string) => setReportingCurrency(value)}
          />
        </div>

        <div className={styles.group}>
          <Title level={5}>
            Available Dimensions
            <TooltipIcon message='This is a placeholder tooltip message for Available Dimensions.' />
          </Title>
          <Tooltip
            title={
              'In order to enable this feature, you need to enable first the Enable Consolidated Transaction View'
            }
            open={!consolidatedTxnView && isTooltipVisibleRC}
          >
            <Select
              loading={false}
              mode='multiple'
              className={styles.select}
              placeholder='Please select'
              disabled={isLoadingOptions || !consolidatedTxnView}
              defaultValue={[]}
              value={availableDimensions}
              options={fetchedOptions?.data.map((option: any) => ({
                ...option,
                value: option.api_name,
                disabled: option.api_name === 'g_vendor_id' // AC-746: Vendor will always be available
              }))}
              onChange={(value: string[]) => setAvailableDimensions(value)}
              onMouseEnter={() => handleHoverChangeRC(true)}
              onMouseLeave={() => handleHoverChangeRC(false)}
              fieldNames={{ label: 'label', value: 'api_name' }}
              virtual={false}
              data-cy='available-dimensions-multiselect'
              data-testid='available-dimensions-multiselect'
            />
          </Tooltip>
        </div>

        <div className={styles.group}>
          <Title level={5}>
            Prior Period Data
            <TooltipIcon message='This is a placeholder tooltip message for Prior Period Data.' />
          </Title>
          <p>
            <CheckboxRow
              label='Show on vendor accrual forms'
              defaultChecked={
                data?.prior_period_information_on_vendor_accrual_forms
              }
              checked={priorPeriodVendor}
              onChange={() => {
                setPriorPeriodVendor(!priorPeriodVendor)
              }}
            />
          </p>
          <p>
            <CheckboxRow
              label='Show on internal accrual forms'
              defaultChecked={
                data?.prior_period_information_on_internal_accrual_forms
              }
              checked={priorPeriodInternal}
              onChange={() => {
                setPriorPeriodInternal(!priorPeriodInternal)
              }}
            />
          </p>
          <p>
            <CheckboxRow
              label='Show on transaction lines'
              defaultChecked={
                data?.prior_period_information_on_transaction_lines
              }
              checked={priorPeriodTransaction}
              onChange={() => {
                setPriorPeriodTransaction(!priorPeriodTransaction)
              }}
            />
          </p>
        </div>
      </div>

      <Title level={4} className={styles.section}>
        Transactions
      </Title>
      <div className={styles.content}>
        <div className={styles.group}>
          <p>
            <CheckboxRow
              label='QJE Creation Validation'
              defaultChecked={data?.validate_qje_sources}
              checked={validateQjeSources}
              onChange={() => {
                setValidateQjeSources(!validateQjeSources)
              }}
            />
            <TooltipIcon message='This is a placeholder tooltip message for QJE Creation Validation.' />
          </p>
        </div>

        <div className={styles.group}>
          <p>
            <Popconfirm
              title='Once you turn on the “Enable Consolidated Transaction View", you can no longer turn it off. Are you sure you want to enable this feature?'
              okText='Yes'
              cancelText='Cancel'
              open={showPopConfirm}
              onConfirm={() => {
                setConsolidatedTxnView(true)
                setShowPopConfirm(false)
              }}
              onCancel={() => {
                setConsolidatedTxnView(data?.consolidated_transaction_lines!)
                setShowPopConfirm(false)
              }}
              placement='left'
            >
              <Checkbox
                data-testid='consolidated-txn-checkbox'
                data-cy='consolidated-txn-checkbox'
                defaultChecked={data?.consolidated_transaction_lines}
                disabled={isConsoFlagDisabled()}
                onClick={(e: any) => {
                  setConsolidatedTxnView(!consolidatedTxnView)
                  setShowPopConfirm(!consolidatedTxnView)

                  if (e.target.checked === false) {
                    setConsiderBothDebitAndCreditValues(false)
                    setAccountTypesToShowInReviewCenter([])
                  }
                }}
                checked={consolidatedTxnView}
              >
                Consolidated Transaction View
              </Checkbox>
              <TooltipIcon message={getConsoTooltip()} />
            </Popconfirm>
          </p>
          <p style={{ padding: '0px 26px' }}>
            <Title level={5}>Proposed Accrual Amount Hierarchy</Title>
            <Tooltip
              title={
                'This field is disabled because the accrual launch for this period is scheduled for tomorrow or it has started already.'
              }
              open={isDisabledProposedAccAmountHierarchy() && isTooltipVisible}
            >
              <Select
                className={styles.select}
                placeholder='Please select'
                disabled={isDisabledProposedAccAmountHierarchy()}
                defaultValue={data?.proposed_accrual_amount_hierarchy!}
                options={[
                  {
                    label: 'Vendor -> Internal -> Calculated',
                    value: 'Vendor.Internal.Calculated'
                  },
                  {
                    label: 'Vendor -> Calculated -> Internal',
                    value: 'Vendor.Calculated.Internal'
                  },
                  {
                    label: 'Internal -> Vendor -> Calculated',
                    value: 'Internal.Vendor.Calculated'
                  },
                  {
                    label: 'Internal -> Calculated -> Vendor',
                    value: 'Internal.Calculated.Vendor'
                  },
                  {
                    label: 'Calculated -> Vendor -> Internal',
                    value: 'Calculated.Vendor.Internal'
                  },
                  {
                    label: 'Calculated -> Internal -> Vendor',
                    value: 'Calculated.Internal.Vendor'
                  }
                ]}
                onChange={handleOnChangeField}
                onMouseEnter={() => handleHoverChange(true)}
                onMouseLeave={() => handleHoverChange(false)}
              />
            </Tooltip>
          </p>
        </div>

        <div className={styles.group}>
          <p>
            <CheckboxRow
              label='Automatic Prepaid Adjustment'
              defaultChecked={data?.negative_accrual_amount_to_prepaid_entry}
              checked={negativeAccrualAmountToPrepaidEntry}
              onChange={() => {
                setNegativeAccrualAmountToPrepaidEntry(
                  !negativeAccrualAmountToPrepaidEntry
                )
              }}
              dataTestId='prepaid-entry-checkbox'
              dataCy='prepaid-entry-checkbox'
            />
            <TooltipIcon message='This is a placeholder tooltip message for Automatic Prepaid Adjustment.' />
          </p>
        </div>
      </div>

      <Title level={4} className={styles.section}>
        Review Center
      </Title>
      <div className={styles.content}>
        <div className={styles.group}>
          <p>
            <CheckboxRow
              disabled={
                isLoading || isFeatureFlagsLoading || !consolidatedTxnView
              }
              label='Account for credit values in the Review Center'
              defaultChecked={data?.consider_both_debit_and_credit_values}
              checked={considerBothDebitAndCreditValues}
              onChange={() => {
                setConsiderBothDebitAndCreditValues(
                  !considerBothDebitAndCreditValues
                )
              }}
              dataTestId='rc-debit-credit-checkbox'
              dataCy='rc-debit-credit-checkbox'
              popoverMessage={
                isLoading || isFeatureFlagsLoading || !consolidatedTxnView
                  ? 'In order to turn on this feature, you need to enable first the Enable Consolidated Transaction View'
                  : undefined
              }
            />
            <TooltipIcon message='This is a placeholder tooltip message for Account for credit values in the Review Center.' />
          </p>
          <p style={{ padding: '0px 26px' }}>
            <Title level={5}>Account types to show in the Review Center</Title>
            <Select
              loading={isLoading || isRefetching}
              mode='multiple'
              style={{ width: '30%', minWidth: '300px' }}
              placeholder='Please select'
              disabled={
                isLoading ||
                isFeatureFlagsLoading ||
                !considerBothDebitAndCreditValues
              }
              defaultValue={accountTypesToShowInReviewCenter ?? undefined}
              value={accountTypesToShowInReviewCenter ?? undefined}
              onChange={(value: string[]) =>
                setAccountTypesToShowInReviewCenter(value)
              }
            >
              {txnGlobalSettings['transaction.coa_account_type']?.map(
                (option: string) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                )
              )}
            </Select>
          </p>
        </div>
      </div>

      <GButton
        onClick={() => {
          if (
            considerBothDebitAndCreditValues &&
            (!accountTypesToShowInReviewCenter ||
              accountTypesToShowInReviewCenter?.length === 0)
          ) {
            message.error(
              'Cannot be saved. Must have at least one account type to show in Review Center.'
            )
          } else if (availableDimensions.length === 0) {
            message.error(
              'Cannot be saved. Must have at least one available dimensions selected.'
            )
          } else
            handleSave({
              prior_period_information_on_vendor_accrual_forms:
                priorPeriodVendor,
              prior_period_information_on_internal_accrual_forms:
                priorPeriodInternal,
              prior_period_information_on_transaction_lines:
                priorPeriodTransaction,
              validate_qje_sources: validateQjeSources,
              consolidated_transaction_lines: consolidatedTxnView,
              proposed_accrual_amount_hierarchy: propAccrualAmountHierarchy,
              negative_accrual_amount_to_prepaid_entry:
                negativeAccrualAmountToPrepaidEntry,
              consider_both_debit_and_credit_values:
                considerBothDebitAndCreditValues,
              account_types_to_show_in_review_center:
                accountTypesToShowInReviewCenter,
              available_dimensions: availableDimensions,
              reporting_currency: reportingCurrency
            })
        }}
        loading={putIsLoading}
      >
        Save
      </GButton>
    </div>
  )
}

export default TxnEnableFeaturesQ3
