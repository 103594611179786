/** React Imports */
import React, { useState } from 'react'

/** Antd Imports */
import { Alert, Button, Modal, Typography } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

/** Store */
import useAccrualManagerStore from '../manager/accrual-manager-store'

/** Utils */
import isGappifyAdministrator from 'src/pages/Settings/utils/isGappifyAdministrator'
import isSystemAdministrator from 'src/pages/Settings/utils/isSystemAdministrator'
import isCloseManager from 'src/pages/Settings/utils/isCloseManager'

const { Text } = Typography

const SyncModal: React.FC = () => {
  /** States */
  const [isModalOpen, setIsModalOpen] = useState(false)

  /** Hooks */
  const { syncStatus, setSyncStatus } = useAccrualManagerStore()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setSyncStatus('IN_PROGRESS')
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const userHasPermission =
    isGappifyAdministrator() || isSystemAdministrator() || isCloseManager()

  return (
    <>
      <Button
        type='primary'
        data-testid='sync-all-button'
        data-cy='sync-all-button'
        icon={
          <SyncOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        }
        onClick={showModal}
        disabled={syncStatus === 'IN_PROGRESS' || !userHasPermission}
      >
        Sync
      </Button>

      <Modal
        title='Sync All Lines'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        okText='Confirm'
        okButtonProps={
          {
            'data-testid': 'modal-message',
            'data-cy': 'modal-message'
          } as any
        }
        data-testid='sync-all-modal'
        data-cy='sync-all-modal'
      >
        <Text
          style={{ color: '#777' }}
          data-testid='sync-all-modal-message'
          data-cy='sync-all-modal-message'
        >
          Would you like to sync all data on Accrual Manager to match source
          vendors and source purchase orders? Click ‘Confirm’ to proceed.{' '}
        </Text>

        <Alert
          data-testid='sync-all-modal-warning'
          data-cy='sync-all-modal-warning'
          message='Warning: Any edits previously made in Accrual Manager will be overridden by this sync action. This action cannot be undone.'
          type='warning'
        />
      </Modal>
    </>
  )
}

export default SyncModal
