/** Antd imports */
import { Button, Card, Space, Typography } from 'antd'
import Title from 'antd/lib/typography/Title'

/** Moment */
import moment from 'moment-timezone'

/** Components */
import TooltipIcon from '../TooltipIcon'
import SyncModal from './components/SyncModal'

/** React Imports */
import { useEffect } from 'react'

/** Hooks */
import useAccrualManagerStore from './manager/accrual-manager-store'

/** Utils */
import { SYNC_STATUSES } from './constants/constants'

const { Text } = Typography

/** Last Sync Date (placeholder) */
const formattedDate = moment()
  .tz('America/Los_Angeles')
  .format('YYYY-MM-DD, hh:mm:ss A (z)')

const AccrualManager = () => {
  const { syncStatus, setSyncStatus } = useAccrualManagerStore()

  useEffect(() => {
    /** Placeholder for now */
    setSyncStatus('SUCCESS')
  }, [setSyncStatus])

  return (
    <>
      {/* Title */}
      <Title
        level={3}
        data-testid='accrual-manager-header'
        data-cy='accrual-manager-header'
        style={{ marginBottom: 15 }}
      >
        Accrual Manager
      </Title>

      {/* Card */}
      <Card
        data-testid='accrual-manager-card'
        data-cy='accrual-manager-card'
        bodyStyle={{ display: 'flex', gap: 75 }}
      >
        <Text>
          Sync All Lines
          <TooltipIcon
            data-testid='sync-all-tooltip'
            data-cy='sync-all-tooltip'
            message='Use this action to sync all Accrual Manager line data to match the source system.'
          />
        </Text>
        <Space direction='vertical'>
          <SyncModal />
          <Space align='start'>
            {SYNC_STATUSES[syncStatus!]?.icon}
            <Space direction='vertical'>
              <Text
                strong
                data-testid='succesfully-synced-text'
                data-cy='succesfully-synced-text'
              >
                {SYNC_STATUSES[syncStatus!]?.label}
              </Text>
              <Text
                type='secondary'
                data-testid='last-sync-text'
                data-cy='last-sync-text'
              >
                Last Sync: {formattedDate}
              </Text>

              {/* TODO: Sync Details should trigger a modal on click */}
              {syncStatus !== 'IN_PROGRESS' && (
                <Button
                  type='link'
                  style={{ color: '#725bb4', padding: 0 }}
                  data-testid='sync-details-button'
                  data-cy='sync-details-button'
                >
                  Sync Details
                </Button>
              )}
            </Space>
          </Space>
        </Space>
      </Card>
    </>
  )
}

export default AccrualManager
