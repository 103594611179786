// ** React Imports
import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// ** AntD Imports
import { Layout } from 'antd'

// ** Custom Component Imports
import Collapsible from './components/Collapsible'
import SelectedSettingsTitle from './components/SelectedSettingsTitle'

// ** Hooks Imports
import useNavigateSettings from './hooks/useNavigateSettings'

// ** Style Imports
import styles from './styles/main.module.css'
import Flag from 'src/features/Flag'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

// =================================================================
const Settings: FC = (): JSX.Element => {
  // ** AntD **
  const { Content } = Layout

  // ** Hooks **
  const { checkNavigation } = useNavigateSettings()

  useEffect(() => {
    checkNavigation('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout className={styles.container}>
      <Collapsible />
      <Layout className={styles.layout} data-testid='settings-page-container'>
        <Content className={styles.content}>
          <Flag
            authorizedFlags={[FEATURE_FLAGS.edinburgh]}
            renderOn={<SelectedSettingsTitle />}
            renderOff={<></>}
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Settings
